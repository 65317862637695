import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { useCallback } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import CustomModal from "./CustomModal";



const Slider = ({ images, video, vimeoIdList }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [image, setImage] = React.useState();
  const [vimeoId, setVimeoId] = React.useState("");
  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: images.length <= 3 ? 2 : 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];

  const handleClick = useCallback((image, id) => {
    setImage(image);
    setModalShow(true);
    setVimeoId(id);
  }, []);

  if (images === null) return null;

  return (
    <StyledSlide>
      <Slide
        slidesToScroll={1}
        slidesToShow={1}
        responsive={responsiveSettings}
        duration={10000}
        autoplay={false}
      >
        {images.map((image, idx) => {
          const id = video && vimeoIdList[idx];
          return (
            <div
              onClick={() => handleClick(getImage(image), id)}
              key={idx}
              className="each-slide-effect"
              style={{
                padding: "20px",
                cursor: "pointer",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <GatsbyImage image={getImage(image)} alt="Game Post Image" />
            </div>
          );
        })}
      </Slide>
      <CustomModal
        image={image}
        show={modalShow}
        onHide={() => setModalShow(false)}
        video={video}
        vimeoId={vimeoId}
      />
    </StyledSlide>
  );
};

const StyledSlide = styled.div`
  .images-wrap div {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export default Slider;
