import React from "react";
import classNames from "classnames";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Hero from "../components/hero";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import Slider from "../components/Slider";

import variables from "../contants/variables";
import Seo from "../components/seo";
import VideoSlider from "../components/VideoSlider";

const GamePostTemplate = ({ data, location, path }) => {
  const post = data.contentfulGamePost;
  const previous = data.previous;
  const next = data.next;
  const image = getImage(data.contentfulGamePost?.image);
  const heroimage = post.heroImage?.gatsbyImageData;
  const gamePreviewContent = post.gamePreviewContent;
  const gameContent = post.gameContent;
  // const firstText =
  //   post.childContentfulGamePostFirstTextTextNode?.childMarkdownRemark.html;
  // const secondText =
  //   post.childContentfulGamePostSecondTextTextNode?.childMarkdownRemark.html;
  const sliderImages = post.sliderImages;
  const sliderImagesProcess = post.sliderImagesProcess;
  const sliderVideoImages = post.sliderVideoImages;
  const vimeoIdList = post.vimeoIdList;
  const hasIframe = post.iframe;
  const iframeSrc = post.iframeSrc;

  return (
    <Layout location={location} path={path}>
      <Seo title={post.title} />
      <div className="img-container">
        <Hero image={heroimage} title={post.title} />
        <div className="img-overlay">
          <h1>{post.title}</h1>
        </div>
      </div>

      {/*Content*/}
      <StyledContainer>
        <Row className="mt-3 mt-md-0 p-md-5">
          <Col md={post.ytVideo || post.vimeoVideoId ? 7 : 12}>
            <div>{renderRichText(gamePreviewContent)}</div>
          </Col>
          {/* <Col
            md={post.ytVideo || post.vimeoVideoId ? 7 : 12}
            className="my-auto"
            dangerouslySetInnerHTML={{
              __html: firstText,
            }}
          /> */}
          {post.vimeoVideoId && (
            <Col
              md={5}
              className={classNames("mb-3", { "d-none": !post.vimeoVideoId })}
            >
              <div className="video-container">
                <iframe
                  title="vimeo"
                  src={`https://player.vimeo.com/video/${post.vimeoVideoId}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              </div>
            </Col>
          )}
        </Row>
        {/*PREZI*/}
        {hasIframe && (
          <Row className="pb-5 p-md-5">
            <Col>
              <div className="video-container">
                <iframe
                  title="prezi"
                  src={iframeSrc}
                  id="iframe_container"
                  frameBorder="0"
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allow="autoplay; fullscreen"
                  height="315"
                  width="560"
                ></iframe>
              </div>
            </Col>
          </Row>
        )}
        <Row
          className={classNames({ "d-none": !image && !gameContent }, "p-md-5")}
        >
          <Col md={4} className={classNames({ "d-none": !gameContent })}>
            {image && <GatsbyImage image={image} alt="second image" />}
          </Col>
          <Col md={!image ? 12 : 8} className="my-auto pt-3 pt-md-0 ">
            {renderRichText(gameContent)}
          </Col>
          {/* <Col
            md={!image ? 12 : 8}
            className="my-auto pt-3 pt-md-0"
            dangerouslySetInnerHTML={{
              __html: secondText,
            }}
          /> */}
        </Row>
        {sliderImages && (
          <Row className="my-5">
            <Slider images={sliderImages} />
          </Row>
        )}
        {sliderImagesProcess && (
          <Row className="my-5">
            <Slider images={sliderImagesProcess} />
          </Row>
        )}
        {sliderVideoImages && (
          <Row className="my-5">
            <VideoSlider
              images={sliderVideoImages}
              vimeoId={post.vimeoVideoId}
              vimeoIdList={vimeoIdList}
            />
          </Row>
        )}
        <Row className="my-5">
          {(previous || next) && (
            <nav>
              <ul className="article-nav">
                {previous && (
                  <li>
                    <Link to={`/game/${previous.slug}`} rel="prev">
                      ← {previous.title}
                    </Link>
                  </li>
                )}
                {next && (
                  <li>
                    <Link to={`/game/${next.slug}`} rel="next">
                      {next.title} →
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </Row>
      </StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled(Container)`
  h1 {
    margin-bottom: 0;
  }
  .video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .gatsby-image-wrapper,
  iframe {
    border-radius: 20px;
    box-shadow: ${variables.SHADOWS.lightShadow};
  }
  .article-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  .article-nav a {
    border-bottom: 1.5px solid currentColor;
    font-weight: bold;
    color: black;
    -webkit-transition: 0.2s;
  }

  .article-nav a:hover {
    border-bottom-color: transparent;
    color: orange;
  }

  @media (-webkit-min-device-pixel-ratio: 0) {
    .article-nav a {
      background-color: black;
      background-image: -webkit-linear-gradient(
        left,
        orange 0%,
        red 50%,
        transparent 50%
      );
      background-position: 100% 0;
      background-size: 200% 200%;
      color: transparent;
      -webkit-transition: 0.1s 0.2s;
      -webkit-background-clip: text;
    }
    .article-nav a:hover {
      background-position: 0 0;
      color: transparent;
      transition: 0.4s 0;
    }
  }
`;

export default GamePostTemplate;

export const data = graphql`
  query GamePostBySlug(
    $slug: String!
    $previousGameSlug: String
    $nextGameSlug: String
  ) {
    contentfulGamePost(slug: { eq: $slug }) {
      slug
      title
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      gameContent {
        raw
      }
      gamePreviewContent {
        raw
      }
      childContentfulGamePostFirstTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      sliderImages {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      sliderImagesProcess {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      image {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      childContentfulGamePostSecondTextTextNode {
        childMarkdownRemark {
          html
        }
      }
      vimeoVideoId
      sliderVideoImages {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
      }
      vimeoIdList
      iframe
      iframeSrc
    }
    previous: contentfulGamePost(slug: { eq: $previousGameSlug }) {
      slug
      title
    }
    next: contentfulGamePost(slug: { eq: $nextGameSlug }) {
      slug
      title
    }
  }
`;
