import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Modal } from "react-bootstrap";
import { device } from "../utils/breakpoints";
import styled from "styled-components";

const CustomModal = (props) => {
  const image = props.image;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      {image && (
        <Modal.Body className={classNames(props.video && "mx-auto")}>
          {props.video ? (
            <StyledIframe
              title="Vimeo slider modal"
              src={`https://player.vimeo.com/video/${props.vimeoId}`}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
            ></StyledIframe>
          ) : (
            <GatsbyImage image={image} alt="Game Post Image" />
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

const StyledIframe = styled.iframe`
  width: 100%;
  @media ${device.xl} {
    width: 640px;
  }
`

export default CustomModal;
