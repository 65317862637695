import React from "react";
import Slider from "./Slider";

const VideoSlider = ({ images, vimeoId, vimeoIdList }) => {
  return (
    <Slider
      images={images}
      video='true'
      vimeoId={vimeoId}
      vimeoIdList={vimeoIdList}
    ></Slider>
  );
};

export default VideoSlider;
